import React from 'react';

interface MobileStoreButtonProps {
  width: string;
  height: string;
  store: 'ios' | 'android';
  url: string;
  linkProps: { title: string };
}

export const MobileStoreButton: React.FC<MobileStoreButtonProps> = ({
  width,
  height,
  store,
  url,
  linkProps,
}) => {
  const getStoreImage = () => {
    if (store === 'ios') {
      return (
        <img
          src="/get-it-on-app-store-badge.png"
          alt="iOS App Store"
          style={{ width, height }}
        />
      );
    } else {
      return (
        <img
          src="/get-it-on-google-play-badge.png"
          alt="Google Play Store"
          style={{ width, height }}
        />
      );
    }
  };

  return (
    <a
      href={url}
      title={linkProps.title}
      target="_blank"
      rel="noopener noreferrer"
    >
      {getStoreImage()}
    </a>
  );
};
