import React from 'react';
import {
  Button,
  Box,
  Heading,
  Icon,
  Image,
  Text,
  chakra,
  HStack,
} from '@chakra-ui/react';
import meshGradientImgUrl from '../../images/mesh-gradient.png';
import { keyframes } from '@emotion/css';
import { breakpoints, CONTAINER_SIZE } from '../../core/constants';
import { Bubbles } from '../Bubbles';
import { useMediaQuery } from '@react-hook/media-query';
import heroImage from '../../images/hero-image.jpeg';
import { MobileStoreButton } from '../MobileStoreButton';

const moveMeshGradient = keyframes`
  50% {background-position: center;}
`;

export const Hero = () => {
  const isLargerThanLg = useMediaQuery(`(min-width: ${breakpoints.lg})`);
  return (
    <Box pos="relative" overflow="hidden" bg={'white'}>
      <Box maxW={CONTAINER_SIZE} px={{ lg: 4 }} mx="auto">
        <Box
          pos="relative"
          pb={{
            base: 2,
            sm: 6,
            md: 10,
            lg: 18,
            xl: 22,
          }}
          maxW={{
            lg: '2xl',
          }}
          w={{
            lg: 'full',
          }}
          zIndex={1}
          bg={'white'}
          border="solid 1px transparent"
        >
          <Icon
            display={{
              base: 'none',
              lg: 'block',
            }}
            position="absolute"
            right={0}
            top={'-1px'}
            bottom={0}
            h="full"
            w={48}
            color={'white'}
            transform="translateX(50%)"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </Icon>
          <Box
            mx="auto"
            maxW={{
              base: CONTAINER_SIZE,
            }}
            px={{
              base: 4,
              sm: 6,
              lg: 0,
            }}
            mt={{
              base: 10,
              sm: 12,
              md: 16,
              lg: 20,
              xl: 28,
            }}
          >
            <Box
              w="full"
              textAlign={{
                sm: 'center',
                lg: 'left',
              }}
              justifyContent="center"
              alignItems="center"
            >
              <Bubbles
                zIndex={2}
                bubbles={
                  isLargerThanLg
                    ? [
                        { left: 450, top: 200, size: 32 },
                        { left: 450, top: -50, size: 12, opacity: 0.25 },
                        { left: 250, top: -90, size: 4, opacity: 0.35 },
                      ]
                    : []
                }
              />
              <Heading
                as="h1"
                fontSize={{
                  base: '4xl',
                  sm: '5xl',
                  md: '6xl',
                }}
                letterSpacing="tight"
                lineHeight="short"
                bgImage={meshGradientImgUrl}
                bgSize={'cover'}
                backgroundClip={'text'}
                bgPos={'20% left'}
                animation={`${moveMeshGradient} 5s infinite`}
              >
                Split expenses effortlessly
              </Heading>
              <Text
                mt={{
                  base: 3,
                  sm: 5,
                  md: 5,
                }}
                fontSize={{
                  sm: 'lg',
                  md: 'xl',
                }}
                maxW={{
                  sm: 'xl',
                }}
                mx={{
                  sm: 'auto',
                  lg: 0,
                }}
                color="gray.500"
              >
                Share your expenses easily when traveling with friends, shopping
                with your partner or attending a family event.
              </Text>
              <Box
                mt={{
                  base: 5,
                  sm: 8,
                }}
                display={{
                  sm: 'flex',
                }}
                justifyContent={{
                  sm: 'center',
                  lg: 'start',
                }}
                fontWeight="extrabold"
              >
                <Button
                  colorScheme={'brand'}
                  size={'lg'}
                  as={chakra.a}
                  href={process.env.GATSBY_APP_URL ?? '#'}
                >
                  Get started
                </Button>
              </Box>
              <Box
                mb={{
                  base: 6,
                  xl: 2,
                }}
                mt={8}
              >
                <HStack
                  spacing={4}
                  justifyContent={{
                    sm: 'center',
                    lg: 'start',
                  }}
                >
                  <MobileStoreButton
                    width="148px"
                    height="50px"
                    store="ios"
                    url="https://apps.apple.com/app/instatab/id1668679595"
                    linkProps={{ title: 'Download instatab for iOS' }}
                  />
                  <MobileStoreButton
                    width="168.5px"
                    height="50px"
                    store="android"
                    url="https://play.google.com/store/apps/details?id=com.mangoarray.instatab"
                    linkProps={{ title: 'Download instatab for Android' }}
                  />
                </HStack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display={{
          base: 'none',
          lg: 'block',
        }}
        position={{
          lg: 'absolute',
        }}
        top={{
          lg: 0,
        }}
        bottom={{
          lg: 0,
        }}
        right={{
          lg: 0,
        }}
        w={{
          lg: '50%',
        }}
        border="solid 1px transparent"
      >
        <Image
          h={[56, 72, 96, 'full']}
          w="full"
          fit="cover"
          src={heroImage}
          alt="Three friends looking at a map"
          transform={'scale(1.25)'}
          loading="lazy"
        />
      </Box>
    </Box>
  );
};
