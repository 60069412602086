import { css, cx } from '@emotion/css';
import * as React from 'react';
import './PhoneFrame.css';

type Props = {
  imgSrc?: string;
  scale?: number;
  bgPosition?: string;
};

const WIDTH = 372;
const HEIGHT = 734;

export const PhoneFrame: React.FC<Props> = ({
  imgSrc = '',
  scale = 1,
  bgPosition = 'center center',
}) => {
  return (
    <div
      role="img"
      aria-label="An image frame that looks like a smart phone"
      className={cx('iphone_illustration_css', {
        [css`
          transform: scale(${scale});
          width: ${WIDTH * scale}px !important;
          height: ${HEIGHT * scale}px !important;
        `]: scale !== 1,
      })}
    >
      <div className="steel_band">
        <div className="mute_rocker"></div>
        <div className="volume up"></div>
        <div className="volume down"></div>
        <div className="sleep_wake"></div>
        <div className="plastic_band">
          <div className="glass_face">
            <div className="camera"></div>
            <div className="speaker"></div>
            <div className="home_button"></div>
            <div className="gloss"></div>
            <div className="screen">
              <div
                className={cx('stage', {
                  [css`
                    background-image: url('${imgSrc}') !important;
                    background-size: cover !important;
                    background-position: ${bgPosition} !important;
                  `]: imgSrc.length > 0,
                })}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
