import * as React from 'react';
import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
  Flex,
} from '@chakra-ui/react';
import { AiOutlineBarChart } from 'react-icons/ai';
import { TbFreeRights } from 'react-icons/tb';
import { GiBrain } from 'react-icons/gi';
import { BsCurrencyExchange } from 'react-icons/bs';
import { BiDevices } from 'react-icons/bi';
import { FaFileCsv, FaUsers } from 'react-icons/fa';
import { CONTAINER_SIZE, gaps, palette } from '../core/constants';

const features = [
  {
    id: 0,
    title: 'Free to use',
    text: 'Use instatab for free at any time. All you need is a web browser.',
    icon: TbFreeRights,
  },
  {
    id: 1,
    title: 'Optimized tab settling',
    text: 'Our algorithm shines with multiple participants. It will work out the least amount of transactions required to settle the tab.',
    icon: GiBrain,
  },
  {
    id: 2,
    title: 'Unintrusive sign-up',
    text: 'You only need to enter your email address and use the sign-in code you receive to start tracking expenses.',
    icon: BiDevices,
  },
  {
    id: 3,
    title: 'Invite friends easily',
    text: 'When you create a tab you can generate a share link. Send it to your friends and they can join with one click.',
    icon: FaUsers,
  },
  {
    id: 4,
    title: 'Multi-currency support',
    text: 'Set the default currency on the tab, then file expenses in any currency. We handle the conversion.',
    icon: BsCurrencyExchange,
  },
  {
    id: 5,
    title: 'Export your tab',
    text: 'Would you like to take a closer look at your data and run your own analysis? Export your expenses as a CSV file with one click.',
    icon: FaFileCsv,
  },
  {
    id: 6,
    title: 'Analyze your data',
    text: 'We generate charts based on your tab data so that you can better understand and analyze your spending.',
    icon: AiOutlineBarChart,
  },
  {
    id: 7,
    title: 'Use on all devices',
    text: 'Do you want to use instatab on your phone without installing an app? No problem, go ahead and use our responsive web app.',
    icon: BiDevices,
  },
];

export const Features = () => {
  return (
    <Flex bg={'#FFECE6'} justifyContent={'center'} id={'features'}>
      <Box
        p={4}
        my={{
          base: gaps.section.base * 2,
          lg: gaps.section.lg * 2,
        }}
      >
        <Stack spacing={4} as={Container} maxW={'2xl'} textAlign={'center'}>
          <Heading mb={4}>Features</Heading>
          <Text color={'gray.800'}>
            instatab is a simple, modern and easy to use platform to track and
            share your expenses. Let our app take care of splitting the tab,
            while you&lsquo;re enjoying your time.
          </Text>
        </Stack>

        <Container maxW={CONTAINER_SIZE} mt={16}>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
            {features.map((feature) => (
              <HStack key={feature.id} align={'top'}>
                <Box color={palette.themePrimary} px={2}>
                  <Icon
                    pos="relative"
                    top={'0.5'}
                    as={feature.icon}
                    w={5}
                    h={5}
                  />
                </Box>
                <VStack align={'start'}>
                  <Text color={'gray.700'} fontWeight={600}>
                    {feature.title}
                  </Text>
                  <Text color={'gray.800'}>{feature.text}</Text>
                </VStack>
              </HStack>
            ))}
          </SimpleGrid>
        </Container>
      </Box>
    </Flex>
  );
};
