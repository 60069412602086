import React, { ReactNode } from 'react';
import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
} from '@chakra-ui/react';
import { CONTAINER_SIZE } from '../core/constants';
import user1Avatar from '../images/avatar-user-aileen.jpeg';
import user2Avatar from '../images/avatar-user-nick.jpeg';
import user3Avatar from '../images/avatar-user-mary.jpeg';

const Testimonial = ({ children }: { children: ReactNode }) => {
  return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
  return (
    <Stack
      bg={'white'}
      boxShadow={'lg'}
      p={8}
      rounded={'xl'}
      align={'center'}
      pos={'relative'}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: 'solid transparent',
        borderLeftWidth: 16,
        borderRight: 'solid transparent',
        borderRightWidth: 16,
        borderTop: 'solid',
        borderTopWidth: 16,
        borderTopColor: 'white',
        pos: 'absolute',
        bottom: '-16px',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Heading as={'h3'} fontSize={'xl'}>
      {children}
    </Heading>
  );
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
  return (
    <Text textAlign={'center'} color={'gray.600'} fontSize={'sm'}>
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({
  src,
  name,
  title,
}: {
  src: string;
  name: string;
  title: string;
}) => {
  return (
    <Flex align={'center'} mt={8} direction={'column'}>
      <Avatar src={src} mb={2} />
      <Stack spacing={-1} align={'center'}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={'sm'} color={'gray.600'}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export const Testimonials = () => {
  return (
    <Box mb={6}>
      <Container maxW={CONTAINER_SIZE} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={'center'}>
          <Heading mb={4}>Our Users Speak</Heading>
          <Text color={'gray.600'}>
            Hear from people who are already putting instatab to good use
          </Text>
        </Stack>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Traveling</TestimonialHeading>
              <TestimonialText>
                I used your app on the last trip with my friends. It made our
                lives easier. We no longer have to worry about splitting the
                bill.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={user1Avatar}
              name={'Aileen'}
              title={'Student'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Night out</TestimonialHeading>
              <TestimonialText>
                We no longer have to keep track of who buys the next round!
                Thanks for splitting the tab for us.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={user2Avatar}
              name={'Nick'}
              title={'HR Specialist'}
            />
          </Testimonial>
          <Testimonial>
            <TestimonialContent>
              <TestimonialHeading>Easy budgeting</TestimonialHeading>
              <TestimonialText>
                My husband and I use your product to track our household
                expenses. It so easy to use from my phone.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={user3Avatar}
              name={'Mary'}
              title={'Journalist'}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
};
