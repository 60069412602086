import * as React from 'react';
import type { HeadFC } from 'gatsby';
import './index.css';
import { Hero } from '../components/Hero';
import { Features } from '../components/Features';
import { Testimonials } from '../components/Testimonials';
import { HowItWorks } from '../components/HowItWorks/HowItWorks';
import { SEO } from '../components/Seo';
import { PageLayout } from '../components/PageLayout';

const IndexPage = () => {
  return (
    <PageLayout>
      <Hero />
      <Features />
      <HowItWorks />
      <Testimonials />
    </PageLayout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <SEO title="Split expenses effortlessly - instatab" />
);
