import { SystemStyleObject } from '@chakra-ui/react';
import { useCallback } from 'react';
import { palette } from '../../core/constants';
import { Bubble } from './types';

export function useMakeBubble() {
  const makeBubble: (bubble: Bubble) => SystemStyleObject = useCallback(
    (bubble: Bubble) => ({
      content: '""',
      display: 'block',
      width: `${bubble.size}em`,
      height: `${bubble.size}em`,
      borderRadius: '50%',
      backgroundColor: palette.themePrimary,
      opacity: bubble.opacity ?? 0.15,
      position: 'absolute',
      top: bubble.top,
      left: bubble.left,
      zIndex: -1,
    }),
    [],
  );

  return makeBubble;
}
