import {
  Box,
  Center,
  Container,
  Heading,
  SimpleGrid,
  SystemStyleObject,
  Text,
} from '@chakra-ui/react';
import { useMediaQuery } from '@react-hook/media-query';
import React, { useMemo } from 'react';
import { breakpoints, CONTAINER_SIZE, palette } from '../../core/constants';
import { Bubble, Bubbles } from '../Bubbles';
import { PhoneFrame } from '../PhoneFrame/PhoneFrame';
import createTabImg from './create-tab.png';
import logExpensesImg from './log-expenses.png';
import splitTheTabImg from './split-the-tab.png';

type InstructionsProps = {
  bubbles?: Bubble[];
  title: string;
  description: string;
  stepNumber: string;
};

const Instructions: React.FC<InstructionsProps> = ({
  bubbles = [],
  title,
  description,
  stepNumber,
}) => {
  const isLargerThanLg = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  const stepNumberStyles: SystemStyleObject = useMemo(
    () => ({
      content: `"${stepNumber}"`,
      display: 'flex',
      width: '2em',
      height: '2em',
      borderRadius: '50%',
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: palette.themePrimary,
      position: 'absolute',
      left: 0,
      top: isLargerThanLg ? '0' : '-0.15em',
      color: palette.themePrimary,
      fontWeight: 'bold',
      fontSize: '1.15em',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    [isLargerThanLg, stepNumber],
  );

  return (
    <Center>
      {isLargerThanLg && <Bubbles bubbles={bubbles} />}
      <Box
        maxW={'2xl'}
        pos="relative"
        pl={{
          base: 0,
          lg: '2.25em',
        }}
        _after={stepNumberStyles}
        mt={{
          base: 24,
          lg: 0,
        }}
        mb={{
          base: -12,
          lg: 0,
        }}
      >
        <Heading size="lg" mb={6} pl={{ base: '2em', lg: '0.5em' }}>
          {title}
        </Heading>
        <Text pl={{ base: 0, lg: '1em' }} maxW={'lg'}>
          {description}
        </Text>
      </Box>
    </Center>
  );
};

export const HowItWorks = () => {
  const isLargerThanLg = useMediaQuery(`(min-width: ${breakpoints.lg})`);

  return (
    <Container
      id={'how-it-works'}
      as={Center}
      flexDir={'column'}
      maxW={CONTAINER_SIZE}
      pt={{
        base: 12,
        lg: 24,
      }}
      pb={{
        base: 32,
      }}
      spacing={12}
    >
      <Heading mb={{ base: -12, lg: 10 }} textAlign={'center'}>
        How It Works
      </Heading>
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap={{ base: 12, lg: 24 }}>
        <Instructions
          stepNumber={'1'}
          title="Open a tab"
          description="Going on a trip with friends? Start a new tab and add them as participants. Once they join you can all start logging your expenses."
          bubbles={[
            { left: -150, top: -300, size: 32 },
            { left: 450, top: 185, size: 16, opacity: 0.25 },
          ]}
        />
        <PhoneFrame
          imgSrc={createTabImg}
          scale={0.75}
          bgPosition={'top center'}
        />
        {isLargerThanLg ? (
          <>
            <PhoneFrame imgSrc={logExpensesImg} scale={0.75} />
            <Instructions
              stepNumber={'2'}
              title="Log expenses"
              description="You can create and assign expenses to any participant on the tab. Review your expense history at any time."
              bubbles={[
                { left: 100, top: -100, size: 24 },
                { left: -350, top: 225, size: 12, opacity: 0.25 },
              ]}
            />
          </>
        ) : (
          <>
            <Instructions
              stepNumber={'2'}
              title="Log expenses"
              description="You can create and assign expenses to any participant on the tab. Review your expense history at any time."
            />
            <PhoneFrame imgSrc={logExpensesImg} scale={0.75} />
          </>
        )}
        <Instructions
          stepNumber={'3'}
          title="Split the tab"
          description="We do the math and split the tab while you enjoy your holiday."
          bubbles={[{ left: 500, top: 50, size: 10, opacity: 0.25 }]}
        />
        <PhoneFrame
          bgPosition="bottom center"
          imgSrc={splitTheTabImg}
          scale={0.75}
        />
      </SimpleGrid>
    </Container>
  );
};
