import * as React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { useMakeBubble } from './useMakeBubble';
import { Bubble } from './types';

type Props = {
  bubbles: Bubble[];
};

export const Bubbles: React.FC<BoxProps & Props> = ({
  bubbles,
  ...boxProps
}) => {
  const makeBubble = useMakeBubble();

  return (
    <>
      {bubbles.map((bubble, index) => (
        <Box
          key={`bubble-${index}`}
          position="relative"
          {...boxProps}
          _after={makeBubble(bubble)}
        />
      ))}
    </>
  );
};
